import React from "react";
//motion
import { motion } from "framer-motion";
//varants
import { fadeIn } from "../variants";
//images
import Img1 from "../assets/portfolio-img1.png";
import Img2 from "../assets/portfolio-img2.png";
import Img3 from "../assets/portfolio-img3.png";
import Img4 from "../assets/portfolio-img4.png";
import Img5 from "../assets/portfolio-img5.png";
import { Link } from "react-scroll";

const Work = () => {
  return (
    <seciton className="section mb-20" id="work">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-x-10">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0"
          >
            {/* text */}
            <div>
              <h2 className="h2 leading-tight text-accent">
                my Latest <br />
                Work
              </h2>
              <p className="max-w-sm mb-16">
                Check out some of the cool stuff I've worked on
              </p>
              {/* left button */}
              <Link to="contact" activeClass="active" smooth={true} spy={true}>
                <button className="btn btn-sm">Let's Work!</button>
              </Link>
            </div>
            {/* image */}
            <a href="https://xpense-t.netlify.app/">
              <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
                {/* overlay */}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={Img1}
                  alt="project1"
                />
                {/* pretitle */}
                <div className=" absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient"> Frontend App</span>
                </div>
                {/* title */}
                <div className=" absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">Xpense</span>
                </div>
              </div>
            </a>
            {/* image */}
            <a href="https://enchanting-salon.netlify.app/">
              <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
                {/* overlay */}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={Img2}
                  alt="project1"
                />
                {/* pretitle */}
                <div className=" absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient"> Frontend App</span>
                </div>
                {/* title */}
                <div className=" absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">Enchanting Salon</span>
                </div>
              </div>
            </a>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex flex-col gap-y-10"
          >
            {/* image */}
            <a href="https://crcontractinginc.com/">
              <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
                {/* overlay */}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={Img5}
                  alt="project1"
                />
                {/* pretitle */}
                <div className=" absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">FullStack App</span>
                </div>
                {/* title */}
                <div className=" absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">
                    Cr Contracting Inc
                  </span>
                </div>
              </div>
            </a>
            {/* image */}
            <a href="https://todo-app-tracker.netlify.app/">
              <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
                {/* overlay */}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={Img4}
                  alt="project1"
                />
                {/* pretitle */}
                <div className=" absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient"> Fullstack App</span>
                </div>
                {/* title */}
                <div className=" absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">TaskMaster</span>
                </div>
              </div>
            </a>
            {/* image */}
            <a href="https://skycastweather.netlify.app/">
              <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
                {/* overlay */}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={Img3}
                  alt="project1"
                />
                {/* pretitle */}
                <div className=" absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient"> Fullstack App</span>
                </div>
                {/* title */}
                <div className=" absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">
                    {" "}
                    SkyCast Weather App{" "}
                  </span>
                </div>
              </div>
            </a>
          </motion.div>
        </div>
      </div>
    </seciton>
  );
};

export default Work;
