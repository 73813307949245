import React from "react";

//images
import Logo from "../assets/logo.svg";

const Header = () => {
  return (
    <header className="py-8">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          {/* logo */}

          <img src={Logo} alt="logo" width={100} height={100} />

          {/* button */}
          <a href="mailto:Jasoncc42@gmail.com">
            <button className="btn btn-sm">Work with me </button>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
