import React from "react";
//motion
import { motion } from "framer-motion";
//variants
import { fadeIn } from "../variants";
import { Link } from "react-scroll";

//services data
const services = [
  {
    name: "FrontEnd Development",
    description:
      "Develop dynamic and responsive user interfaces using the latest frontend technologies to deliver exceptional user experiences. ",
  },
  {
    name: "BackEnd Development",
    description:
      "Proficient in designing and implementing RESTful APIs and optimizing database performance for improved application functionality.",
  },
  {
    name: "UI/UX Design",
    description:
      "Experience in designing user interfaces and experiences that cater to user needs and preferences, utilizing various design principles and frameworks.",
  },
  {
    name: "CMS",
    description:
      "Specialize in developing and customizing WordPress websites, using a combination of WordPress core technologies, plugins, and themes.",
  },
];

const About = () => {
  return (
    <div className="section lg:mb-[200px]" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          {/* text & image */}
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0"
          >
            <h2 className="h2 text-accent mb-6">What I Offer</h2>
            <h3 className="h3 max-w-[455px] mb-16">Full-stack Proficiency</h3>
            <Link
              to="work"
              activeClass="active"
              smooth={true}
              spy={true}
              offset={-150}
            >
              <button className="btn btn-sm">see my work</button>
            </Link>
          </motion.div>
          {/* services */}
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            {/* services list */}
            <div>
              {services.map((services, index) => {
                //destructure service
                const { name, description } = services;
                return (
                  <div
                    className="border-b border-white/20 h-[146px] mb-[38px] flex"
                    key={index}
                  >
                    <div className="max-w-[476px]">
                      <h4 className="text-[20px] tracking-wider font-primary font-semibold mb-6">
                        {name}
                      </h4>
                      <p className="font-secondary leading-tight">
                        {description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default About;
